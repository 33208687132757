import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Logo2 = () => {
  const data = useStaticQuery(graphql`
  query {
    file(relativePath: {eq: "3bpt-logo.jpg"}) {
      childImageSharp {
        fixed(height: 40) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
  }
`)

  return <Img fixed={data.file.childImageSharp.fixed} />
}

export default Logo2
