import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Logo from "../components/logo"
import Logo2 from "../components/logo2"

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `#FFFFFF`,
      marginBottom: `0`,
      boxShadow: `-4px -8px 12px rgba(0,0,0,0.5)`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `.5rem 0 0`,
        textAlign: `center`,
      }}
    >
      <Link alt="Body Solutionz and B3PT Logos" to="/">
        <Logo />
        <Logo2 />
      </Link>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
